import React from "react";
import { InfoPage } from "../components/InfoPage";
import { Layout } from "../components/Layout";
import Metadata from "../components/Metadata";
import { Body } from "../components/Typography";

export default () => {
    return (
        <Layout>
            <Metadata title="Shop" desc="Still Weaving..." />
            <InfoPage title={"Still Weaving..."}>
                <Body>
                    We are working hard fine-tuning the details here. For the
                    time being, we’d like to invite you to discover our
                    campaigns and stay connected through our newsletter.
                </Body>
            </InfoPage>
        </Layout>
    );
};
